import React, {useEffect, useState} from 'react';
import './home.scss';
import {Link} from "react-router-dom";
import {AppCode, service} from "../common/com";
import {history, widthStore} from "../../store";
import {theme0} from "../../utils";

let v;
export const Home = widthStore(({globalStore}) => {
    const {token} = globalStore;
    useEffect(() => {
        if (token) {
            history.replace('/agent/analysis')
        }else if(theme0){
            history.replace('/login')
        }
        onWheel()
        return () => v = null;
    }, [token])
    const [s, ss] = useState(0)
    const sty = {};
    // const sty = {transform: `translate3d(0,${-s * 100}%,0)`}
    const onWheel = e => {
        const target = e && e.currentTarget || document.querySelector('.src')
        const top = target.scrollTop;
        const h = target.offsetHeight*1.3;
        const h0 = target.querySelector('.h0').offsetHeight;
        const h1 = target.querySelector('.h1').offsetHeight;
        // e.preventDefault()
        // if (v) {
        //     v.scrollTop=0;
        if (top + h0 >= h || h1 + h0 <= h) {
            ss(1)
        } else {
            ss(0)
        }
        // }
    }
    return <div className={'full-page home'}>
        <div className={'src'} onScroll={onWheel}>
            <div className={'hfp h0'} style={sty}>
                <div className={'hd'} onClick={() => service.open()}>
                    <i/>
                    <span>联系客服</span>
                </div>
                <h1><i/>
                    <div>OBET 数据管理系统</div>
                </h1>
                <div className={'cs cs1'}>
                    <div className={'c0'}/>
                    <div className={'c1'}/>
                    <div className={'c2'}/>
                </div>
                <div className={'cs cs2'}>
                    <div className={'c0'}>
                        <i/>
                        <span>超高额返佣</span>
                    </div>
                    <div className={'c1'}>
                        <i/>
                        <span>业界最优质服务</span>
                    </div>
                    <div className={'c2'}>
                        <i/>
                        <span>专业的图表分析</span>
                    </div>
                </div>
                <div className={'cs cs3'}>
                    <div className={'c0'}>
                        <i/>
                        <p>商务合作泡泡</p>
                        <span>bt1998（五号免审）</span>
                    </div>
                    <div className={'c1'}>
                        <i/>
                        <p>商务合作邮箱</p>
                        <span>linmingming633@gmail.com</span>
                    </div>
                    <div className={'c2'}>
                        <i/>
                        <p>商务合作Telegram</p>
                        <span>@dahua135</span>
                    </div>
                </div>
                <Link className={'btn'} to={'/login'}>前往登录</Link>
                <div className='dw'>
                    <AppCode/>
                </div>
                <div className={'fb'}>
                    <div className={'c'}>
                        <p><i/><b>强强联手，共创辉煌</b>OBET合营计划，助力您多端、多渠道、多平台推广；提供行业最优质的资源和服务。<i/></p>
                    </div>
                </div>
            </div>
            <div className={'hfp h1' + (s ? ' ac' : '')} style={sty}>
                <div className={'t0'}>
                    <div><i/></div>
                    周详的数据分析报表
                </div>
                <p>我们抛弃笼统的代理数据分析模型</p>
                <p>针对会员的行为动作/留存/转化/来源等方面系统性进行数据统计分析</p>
                <div className={'r'}>
                    <div className={'a0'}/>
                    <div className={'i a1'}><span>01</span><i/><p>佣金概括</p></div>
                    <div className={'i a2'}><span>02</span><i/><p>轻松管理下级会员</p></div>
                    <div className={'i a3'}><span>03</span><i/><p>详细的资金分析</p></div>
                    <div className={'i a4'}><span>04</span><i/><p>留存/转化/流失分析</p></div>
                </div>
            </div>
        </div>
    </div>
})
